.form_container {
    margin: 40px auto;
    max-width: 600px;
}

._trs { transition: all 200ms ease-in-out; }

html, body { height: 100%; }
body { font-family: 'lato', 'helvetica', 'sans-serif'; @include FS(20); 
    background-color: color(background); color: color(font);

    a, p, td { color: color(font); }
} 

p { margin: 20px 0; }

a.btn, button.btn { border: 2px solid color(font); padding: .5em .8em; display: inline-block;
    text-align: center; white-space: nowrap; @extend ._trs;
    &.primary { border-color: color(primary); color: color(primary); }
}