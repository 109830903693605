@-webkit-viewport { width: device-width; }@-moz-viewport { width: device-width; }@-ms-viewport { width: device-width; }@-o-viewport { width: device-width; }@viewport { width: device-width; }
* { padding:0;	margin:0;	border:none; outline: none; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; box-sizing: border-box;
	&::before, &::after { box-sizing: border-box; content:''; }
}
a, li, ul, nav, p, h1, h2 { text-decoration: none;	text-transform: none; font-style: none;	font-variant: none; list-style: none; }
a, p, h1, h2, h3, cite, em, b { font-family: helvetica, arial, sans-serif; font-variant: none; text-decoration: none; }
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary,
time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }
ol, ul, nav ul { list-style: none; } blockquote, q { quotes: none; }
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }
table { border-collapse: collapse; border-spacing: 0;
	}
a { margin:0; padding:0; font-size:100%; vertical-align:baseline; background:transparent; }
ins { background-color:#ff9; color:#000; text-decoration:none; }
mark { background-color:#ff9; color:#000;  font-style:italic; font-weight:bold; }
del { text-decoration: line-through; }
abbr[title], dfn[title] { border-bottom:1px dotted; cursor:help; }
table { border-collapse:collapse; border-spacing:0; }
hr { display:block; height:1px; border:0;  border-top:1px solid #cccccc; margin:1em 0; padding:0;}
textarea { resize: none; }input, select { vertical-align:middle;
	}
.nobr { white-space: nowrap; }a.nobr { display: inline-block; }
._f_w 	{ width: 100%; }._f_h  { height: 100%; }
._hide  { display: none !important; visibility: hidden !important; opacity: 0 !important;
	}
