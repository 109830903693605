@keyframes bg_gradient_header {
    0% { transform: translateX(50px); }
    10% { transform: translateY(50px); }
    20% { transform: translateX(-50px); }
    30% { transform: translateY(-50px); }
    40% { transform: translateX(50px); }
    50% { transform: translateX(50px); }
    60% { transform: translateX(-50px); }
    70% { transform: translateX(-50px); }
    80% { transform: translateX(50px); }
    90% { transform: translateX(50px); }
    100% { transform: translateX(-50px); }
}