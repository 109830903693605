@import "tools/fonts";
@import "tools/reset";
@import "tools/grids";
@import "tools/mixins";
@import "tools/function";


@import "tools/animation";

$colors: (
    background: #fff,
    font: #333,
    primary: #1B2174
);
$z-layers: (
    nav: 100,
    header: 10,
    over: 200
);
$bezier: cubic-bezier(0, 1.28, .99, .11);

@import "tools/base";

header { @include size(f, 100vh); 
    canvas.background { @include pos(a,0); @include size(f); }
    .main_logo { @include size(300px, 100px); 
        @include pos(a,0);top: 120px; margin: auto; z-index: z(over);
        span { @include fZ(40); display: block; @include lH(96); color: color(primary);
            text-transform: uppercase; width: 100%; text-align: center; font-family: 'latoLight';
            letter-spacing: .2em; padding-left: .2em;
        }
    }
    a.to_down { @include size(100px, 50px); @include pos(a,auto,0,40px,0); margin: auto;
        i, span { text-align: center; margin: 0 auto; display: block; }
        i { @include size(30px);  @include fZ(30);  }
        span { width: 100%; @include opacity(0); width: 100%; @extend ._trs; 
            transform: translateY(-20px);
        }

        &:hover { span { @include opacity(1); transform: translateY(0); } }
    }
}

section { padding: 60px 0; }

@import "pages/admin";
@import "pages/home";