@import "../../../node_modules/susy/sass/susy";

@import "simple-grid";
@import "media-queries";

$susy: (
	flow: ltr,
	container: 1200px,
	gutter-position: inside-static,
	last-flow: to,
	columns: 12,
	column-width: 100px,
	gutters: 5px/100px,
	global-box-sieing: border-box
);

.wrap { @include container; position: relative; }