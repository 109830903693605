@import "media-queries";

/* resolution */
@mixin background-image-2x($file, $type, $width, $height) {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx){
    & {
      background-image: url('../img/' + $file + '@2x.' + $type);
      -webkit-background-size: $width $height;
      -moz-background-size: $width $height;
      -o-background-size: $width $height;
      background-size: $width $height;
    }
  }
}
@mixin triangle($direction, $size: 6px, $color: #222){
  content: ''; display: block; position: absolute; height: 0; width: 0;
  @if ($direction == 'up'){
    border-bottom: $size solid $color; border-left: 1/2*$size solid transparent; border-right: 1/2*$size solid transparent;
  }
  @else if ($direction == 'down'){
    border-top: $size solid $color; border-left: 1/2*$size solid transparent; border-right: 1/2*$size solid transparent;
  }
  @else if ($direction == 'left'){
    border-top: 1/2*$size solid transparent; border-bottom: 1/2*$size solid transparent; border-right: $size solid $color;
  }
  @else if ($direction == 'right'){
    border-top: 1/2*$size solid transparent; border-bottom: 1/2*$size solid transparent; border-left: $size solid $color;
  }
}

@mixin clearfix { clear: both; zoom: 1;
  &:before, &:after { content: " "; display: table; }
  &:after { clear: both; line-height: 0; visibility: hidden; }
}
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
} 

@mixin FS($size, $paragraphe: false) {
  $rem: $size / 16;

  font-size: $size + px;
  font-size: $rem + rem;
  line-height: $size + px;
  line-height: $rem + rem;

  @if $paragraphe == true {
    $px: $size * 30; 
    $pxRem: $px / 16;
    max-width: $px + px;
    max-width: $pxRem + rem;
  }
}

@mixin fZ($size) {
  font-size: #{ $size }px;
  font-size: #{ $size /16 }rem;
}
@mixin lH($heightValue: 12 ){
  line-height: #{ $heightValue }px; //fallback for old browsers
  line-height: #{ $heightValue / 16 }rem;
}
@mixin para-max($font-size) {
  $px: $font-size * 30; 
  max-width: #{ $px }px;
  max-width: #{ $px / 16 }rem;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin pos($pos, $t, $r:$t, $b:$t, $l:$r){
  @if $pos == 'a' {
    position: absolute;
  } @elseif $pos == 'r' {
    position: relative;
  } @elseif $pos == 'f' {
    position: fixed;
  }
  top: $t; right: $r; bottom: $b; left: $l;
}

@mixin margin($marg_t, $marg_r:$marg_t, $marg_b:$marg_t, $marg_l:$marg_r) {
  margin-top:    $marg_t;       margin-right: $marg_r;
  margin-bottom: $marg_b;       margin-left:  $marg_l;
}
@mixin padding($pad_t, $pad_r:$pad_t, $pad_b:$pad_t, $pad_l:$pad_r) {
  padding-top:    $pad_t;       padding-right: $pad_r;
  padding-bottom: $pad_b;       padding-left:  $pad_l;
}

@mixin bg($img-uri, $pos:initial, $repeat:no-repeat, $atth:initial, $size:initial) {
  background-image: url($img-uri);
  background-position: $pos;
  background-repeat: $repeat;
  background-attachment: $atth;
  -webkit-background-size: $size;
  -moz-background-size: $size;
  -o-background-size: $size;
  background-size: $size;
}

@mixin size($width, $height: $width) {
  @if $width == f { $width: 100%; }
  @if $height == f { $height: 100%; }

  width: $width; height: $height;
}
@mixin max-size($width, $height:$width) {
  max-width: $width; max-height: $height;
}
@mixin min-size($width, $height:$width) {
	min-width: $width; min-height: $height;
}

@mixin col($nbr, $r: 12) {
  $g: 100 / 12;
  float: left; width: ($nbr * $g) + %; padding: 10px; 
}



