$fontPath: '../fonts/';

@font-face {
    font-family: 'latobold';
    src: url('#{ $fontPath }lato/Lato-Bold-webfont.eot');
    src: url('#{ $fontPath }lato/Lato-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{ $fontPath }lato/Lato-Bold-webfont.woff2') format('woff2'),
         url('#{ $fontPath }lato/Lato-Bold-webfont.woff') format('woff'),
         url('#{ $fontPath }lato/Lato-Bold-webfont.ttf') format('truetype'),
         url('#{ $fontPath }lato/Lato-Bold-webfont.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'latolight';
    src: url('#{ $fontPath }lato/Lato-Light-webfont.eot');
    src: url('#{ $fontPath }lato/Lato-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{ $fontPath }lato/Lato-Light-webfont.woff2') format('woff2'),
         url('#{ $fontPath }lato/Lato-Light-webfont.woff') format('woff'),
         url('#{ $fontPath }lato/Lato-Light-webfont.ttf') format('truetype'),
         url('#{ $fontPath }lato/Lato-Light-webfont.svg#latolight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'lato';
    src: url('#{ $fontPath }lato/Lato-Regular-webfont.eot');
    src: url('#{ $fontPath }lato/Lato-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{ $fontPath }lato/Lato-Regular-webfont.woff2') format('woff2'),
         url('#{ $fontPath }lato/Lato-Regular-webfont.woff') format('woff'),
         url('#{ $fontPath }lato/Lato-Regular-webfont.ttf') format('truetype'),
         url('#{ $fontPath }lato/Lato-Regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@import "font_awesome/font-awesome";