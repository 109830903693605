.under_construction { text-align: center;
}
.logo_header{
	margin: 0 auto;
	right: 0;
	bottom: 0;
	position: absolute;
	left: 0;
	top: 50px;
	height: 350px;
	width: 350px;
}
.main_logo{
	border:none;
}
h1{

	text-align: center;
	font-size: 23px;
	color: color(primary);
}
nav ul li{
	float: left;
	padding: 0 10px;
	margin: 0 10px;

	&:last-child { margin-right: 0}
	&:first-child { margin-left: 0}
}

.main__nav{
	height: 50px;
	width: 100%;
	background-color: color(primary);
	ul{
		float: right;
		padding-right: 10%;
	}
}

.nav:hover{
	background-color: red;
}
.nav__a{
	height: 50%;
	width: 100%;
	color: white;
	line-height: 50px;
	&:hover{
		text-decoration: underline;
	}
}
.propos p {
	text-align: center;
}

.section_project{
	height:1100px;
	color: blue;
}
.project{
	width: 31%;
	background-color: red;
	height: 300px;
	float: left;
	border:	1px solid;
	margin: 1%;
	background: url(../img/logo1.svg) no-repeat center center;
	background-size: cover;
}
.project:nth-child(2){
	
	background: url(../img/ability.svg) no-repeat center center;

}
.project_a{
	display: block;
	height: 100%;
}
.project_a:hover{
	background-color: color(primary);
	margin: 0 auto;
	color: color(background);
	opacity: 0.3;

}
.produit_li{

	background-color: #212121;
	width: 100%;
	height: 200px;
	color: white;
	position: relative;
}
.produit_li h2{
	color: red;
}

.produit_li:nth-child(3){
 background-image:-moz-linear-gradient(45deg, #FE7880, #F38AD0);
 text-align: right;

}




/*    !!!!!!!!!!!!!!!   TEAM !!!!!!!!!!!!!!!!!!      */

.li_team{
	width: 31%;
	height: 300px;
	float: left;
	margin: 1%;
}

.li_team:first-child{
	background: url(../img/team/françois.jpg) no-repeat center center;
	background-size: cover;
}

.li_team:nth-child(2){
	background: url(../img/team/bloshi.jpg) no-repeat center center;
	background-size: cover;
}
.li_team:nth-child(3){
	background: url(../img/team/quentin.jpg) no-repeat center center;
	background-size: cover;
}
.li_team:nth-child(4){
	background: url(../img/team/maxime.jpg) no-repeat center center;
	background-size: cover;
}

.li_team:nth-child(5){
	background: url(../img/team/michel.jpg) no-repeat center center;
	background-size: cover;
}

.li_team:last-child{	
	background: url(../img/team/jerome.jpg) no-repeat center center;
	background-size: cover;
}

.team_content{
	opacity: 0;
	height: 100%;
	text-align: center;
	background-color: color(primary);
	line-height: 120px;

}
.team_content h2{
	color: color(background);
}
.team_content p{
	color: color(background);
}
.team_content:hover{
	opacity: 0.9;
    transition: all ease .4s;

}


footer{
	text-align: center;
}
.under_construction a:hover{
	background-color: color(primary);
	color: color(background);
}
